import React, { useState, useContext, useEffect, useMemo, ReactNode } from "react";
import classNames from "classnames";
import { LoadingIndicatorInline } from "../loading_indicator/index";

import * as styles from "./styles.module.scss";

import {
  fetchTopEvent
} from "utils/ad_engage";

import { MixPanelContext } from "context/mixpanel_context";
import { TopEventCreativeMetadata, TopEventCreativeMetadataFile } from "../../models/top_event_creative";
import {NewTopEventCreativeCard, TopEventCreativeCard} from "../creative_card/index";
import { CURRENT_TOP_EVENT_KEY, TOP_EVENTS } from "../../utils/constants";
import { CategoryOverviewPageHeader, TopEventLanguageSelector } from "./CategoryOverview";
import Paginator from "../paginator";

export interface TopEventOverviewProps {
  pageHeader?: ReactNode;
  pageFooter?: ReactNode;
}

const TopEventOverview: React.FC<(TopEventOverviewProps)> = (props) => {
  const { pageHeader, pageFooter } = props;
  const [topAds, setTopAds] = useState<Array<TopEventCreativeMetadata>>(null);
  const [contentLoading, setContentLoading] = useState<boolean>(true);
  const [topAdsWeek, setTopAdsWeek] = useState<number>(null);
  const [spanishTopAds, setSpanishTopAds] = useState<Array<TopEventCreativeMetadata>>([]);
  const [currentTopEventsLanguage, setCurrentTopEventsLanguage] = useState<String>("English Only");
  const mixpanel = useContext(MixPanelContext);
  const [currentPage, setCurrentPage] = useState(1);
  const pageLimit = 20;

  const currentAds = useMemo(() => {
    return currentTopEventsLanguage == "English Only" ? topAds : spanishTopAds;
  }, [topAds, spanishTopAds, currentTopEventsLanguage]);

  useEffect(() => {
    Promise.all([
      fetchTopEvent()
      .then((data) => { setTopAds(data.data); setTopAdsWeek(data.week) })
      .catch((err) => { console.log(err) }),
      fetchTopEvent(true)
      .then((data) => { setSpanishTopAds(data.data); })
      .catch((err) => { console.log(err) })
    ]).finally(() => { setContentLoading(false); });
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, []);


  const renderContent = () => {
    if (contentLoading) {
      return (
        <div className={classNames("flex-fill-remainder", styles.loadingIndicatorContainer)}>
          <LoadingIndicatorInline width="64px" height="64px" />
        </div>
      )
    }

    return (
      <div className={classNames("flex-fill-remainder has-background-anti-flash-white full-height-and-width-minus-navbar-and-sidebar", styles.categoryOverview)}>
        <div className={classNames("flex-row", styles.categoryOverviewHeader)}>
          <div className={classNames("flex-col", styles.categoryOverviewDisclaimer)}>
            <div className={styles.categoryOverviewDisclaimerText}>
              Only EDO can report the real-time TV performance of every brand advertising in the NFL - <span className={styles.categoryOverviewDisclaimerBoldText}>including Amazon Thursday Night Football.</span>
            </div >
            <div className={styles.categoryOverviewDisclaimerText}>
              When your ad is on the line, access to real-time customer engagement is the only thing that counts.
            </div>
          </div>

          <div className={classNames("flex-col", styles.nflWeekCol)}>
            <div className={styles.nflWeekText}>{`NFL Week ${topAdsWeek || ""}`}</div>
            <div className={styles.nflWeekText}>Data resets weekly on Friday</div>
            <div>
              <TopEventLanguageSelector setCurrentTopEventsLanguage={setCurrentTopEventsLanguage} />
            </div>
          </div>
        </div >
        <div className={"flex-row flex-wrap"}>
          {
            currentAds && currentAds.slice((currentPage - 1) * pageLimit, currentPage * pageLimit).map((entity: TopEventCreativeMetadata, i: number) => {
              return <NewTopEventCreativeCard
                creative_id={entity.creative_id}
                brand_name={entity.brand_name}
                creative_title={entity.creative_title}
                category_name={entity.category_name}
                tv_ad_airings_count={entity.airing_count}
                thumbnail_url={entity.thumbnail_url}
                duration={entity.duration}
                brand_image_url={entity.brand_image_url}
                key={`${i}-creative-card`}
                rank={(i + ((currentPage - 1) * pageLimit)) + 1}
              />;
            })
          }
          <div className={styles.categoryOverviewPaginatorContainer}>
            <Paginator
              extraClassNames={styles.categoryOverviewPaginator}
              totalCount={currentAds ? currentAds.length : 0}
              limitPerPage={pageLimit}
              onPageNumberChange={(newPageNumber: number) => { setCurrentPage(newPageNumber) }}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div >
    )
  }

  return (
    <>
      { pageHeader || <CategoryOverviewPageHeader /> }
      <div className={classNames("fill-parent", styles.categoryContent)}>
        <div className={classNames("flex-row", styles.contentWithFooter)}>
          {renderContent()}
        </div>
        { pageFooter }
      </div>
    </>
  );
};

export default TopEventOverview;
